import { useState, useEffect } from 'react';

import DataTable from 'react-data-table-component';
import AddExperience from './AddExperience';
import UpdateExperience from './UpdateExperience';
import { useSelector, useDispatch } from 'react-redux';
import { fetchExperiences, deleteExperience } from '../../../redux/slice/experienceSlice';
import { XCircleFill } from 'react-bootstrap-icons';
import AdminLoading from '../../../components/Animation/AdminLoading';

function ExperienceList() {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { experiences, experienceLoading, refreshData } = useSelector((state) => ({
    ...state.experience,
  }));

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Company',
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: 'Detail',
      selector: (row) => row.detail,
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: 'İşlemler',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div style={{ display: 'flex'}}>
            <XCircleFill
              onClick={() => {
                const confirmBox = window.confirm(
                  'Gerçekten silmek istiyor musunuz?'
                );
                if (confirmBox === true) {
                  dispatch(deleteExperience(row.id));
                }
              }}
            />
            <div style={{ marginLeft: 5 }}>
              <UpdateExperience id={row.id}> </UpdateExperience>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchExperiences());
  }, []);

  if (refreshData) {
    dispatch(fetchExperiences());
  }
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);
    if (value.length) {
      updatedData = experiences.filter((item) => {
        const startsWith = item.name
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.name.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  return !experienceLoading ? (
    <AdminLoading/>
  ) : (
    <div className='card'>
    <div className='card-header' >
    <div style={{display:'flex'}}>
    <input type='text' className='search-input' id='title' name='title' placeholder=' Ara' onChange={handleFilter} />

          <AddExperience></AddExperience>
        </div>
      </div>
      <div className='react-dataTable'>
        <DataTable
          title='Experience Listesi'
          columns={columns}
          data={searchValue.length ? filteredData : experiences}
          pagination
        />
      </div>
      <div className='card-footer'>
      <div className='card-text mb-0'>
        <span className='fw-bold'>Toplam Kayıt:</span>{' '}
        <span> {experiences.length}</span>
      </div>
      </div>

    </div>
  );
}
export default ExperienceList;
