import { useState, useEffect } from 'react';

import DataTable from 'react-data-table-component';
import AddProject from './AddProject';
import UpdateProject from './UpdateProject';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjects, deleteProject } from '../../../redux/slice/projectSlice';
import { XCircleFill } from 'react-bootstrap-icons';
import AdminLoading from '../../../components/Animation/AdminLoading';

function ProjectList() {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { projects, projectLoading, refreshData } = useSelector((state) => ({
    ...state.project,
  }));

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
    },

    {
      name: 'CategoryId',
      selector: (row) => row.categoryId,
      sortable: true,
    },
    {
      name: 'Sıra',
      selector: (row) => row.sequence,
      sortable: true,
    },
    {
      name: 'İşlemler',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <XCircleFill
              onClick={() => {
                const confirmBox = window.confirm(
                  'Gerçekten silmek istiyor musunuz?'
                );
                if (confirmBox === true) {
                  dispatch(deleteProject(row.id));
                }
              }}
            />
            <div style={{ marginLeft: 5 }}>
              <UpdateProject id={row.id}> </UpdateProject>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchProjects());
    console.log(projects)
  }, []);

  if (refreshData) {
    dispatch(fetchProjects());
  }
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);
    if (value.length) {
      updatedData = projects.filter((item) => {
        const startsWith = item.name
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.name.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  return !projectLoading ? (
    <AdminLoading />
  ) : (
    <div className='card'>
      <div className='card-header' >
        <div style={{ display: 'flex' }}>
          <input type='text' className='search-input' id='title' name='title' placeholder=' Ara' onChange={handleFilter} />

          <AddProject></AddProject>
        </div>
      </div>
      <div className='react-dataTable'>
        <DataTable
          title='Project Listesi'
          columns={columns}
          data={searchValue.length ? filteredData : projects}
          pagination
        />
      </div>
      <div className='card-footer'>
        <div className='card-text mb-0'>
          <span className='fw-bold'>Toplam Kayıt:</span>{' '}
          <span> {projects.length}</span>
        </div>
      </div>

    </div>
  );
}
export default ProjectList;
