import React from 'react';
import ProjectCategoryList from '../../../components/Admin/ProjectCategory/ProjectCategoryList';

function ProjectCategory() {
  return (
    <ProjectCategoryList /> 
  );
}

export default ProjectCategory;
