import React from 'react';
import ContactList from '../../../components/Admin/Contact/ContactList';

function Contact() {
  return (
    <ContactList /> 
  );
}

export default Contact;
