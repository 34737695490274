import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import "./Project.css"

export default function Create({ projects,showModal,setShowModal }) {
  const handleModalShow = () => setShowModal(!showModal);

  return (
     
          <div className='container'>
            <Modal
            size='lg'
              show={showModal}
              onHide={handleModalShow}
              keyboard={false}
              scrollable={true}
            >
              <Modal.Header closeButton>
                <Modal.Title> {projects.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
            <img className="img-fluid" src={projects.imgURL}  alt="Card image cap" />           
            <div className="card  bg-light mb-3 mt-5" >
            <div className="card-body" >
                <div dangerouslySetInnerHTML={{ __html: projects.detail }} />
                {projects.link ? (<a href={projects.link} target="_blank">Projeyi Görüntülemek İçin Tıklayın</a>):(null) }
                </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
  )
}
