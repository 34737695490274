import Lottie from "lottie-react";
import groovyWalkAnimation from "./computerloading.json";
const style = {
  height:"70vh",
};
const AdminLoading = () => {
  
  return <Lottie animationData={groovyWalkAnimation}  style={style} />;
};

export default AdminLoading;