import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AddProjectCategory from './AddProjectCategory';
import UpdateProjectCategory from './UpdateProjectCategory';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjectCategories, deleteProjectCategory } from '../../../redux/slice/projectCategorySlice';
import { XCircleFill } from 'react-bootstrap-icons';
import AdminLoading from '../../../components/Animation/AdminLoading';

function ProjectCategoryList() {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { projectCategories, projectCategoryLoading, refreshData } = useSelector((state) => ({
    ...state.projectCategory,
  }));

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'İşlemler',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <XCircleFill
              onClick={() => {
                const confirmBox = window.confirm(
                  'Gerçekten silmek istiyor musunuz?'
                );
                if (confirmBox === true) {
                  dispatch(deleteProjectCategory(row.id));
                }
              }}
            />
            <div style={{ marginLeft: 5 }}>
              <UpdateProjectCategory id={row.id}> </UpdateProjectCategory>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchProjectCategories());
  }, []);

  if (refreshData) {
    dispatch(fetchProjectCategories());
  }
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);
    if (value.length) {
      updatedData = projectCategories.filter((item) => {
        const startsWith = item.name
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.name.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  return !projectCategoryLoading ? (
    <AdminLoading />
  ) : (
    <div className='card'>
      <div className='card-header' >
        <div style={{ display: 'flex' }}>
          <input type='text' className='search-input' id='title' name='title' placeholder=' Ara' onChange={handleFilter} />

          <AddProjectCategory></AddProjectCategory>
        </div>
      </div>
      <div className='react-dataTable'>
        <DataTable
          title='ProjectCategory Listesi'
          columns={columns}
          data={searchValue.length ? filteredData : projectCategories}
          pagination
        />
      </div>
      <div className='card-footer'>
        <div className='card-text mb-0'>
          <span className='fw-bold'>Toplam Kayıt:</span>{' '}
          <span> {projectCategories.length}</span>
        </div>
      </div>

    </div>
  );
}
export default ProjectCategoryList;
