import React from 'react';
import ExperienceList from '../../../components/Admin/Experience/ExperienceList';

function Experience() {
  return (
    <ExperienceList /> 
  );
}

export default Experience;
