import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import baseApi from "../../baseApi/baseApi"
const initialState = {
  experiences: [],
  experience:null,
  experienceLoading: true,
  refreshData:false,
  updateLoading:true,
  errorMessage: "",
}

export const fetchExperiences = createAsyncThunk(
  'experiences/fetchExperiences',
  async () => {
    const response = await baseApi.get(`/experience`)
    return response.data.result
  }
)
export const fetchExperienceById = createAsyncThunk(
  'experiences/fetchExperienceById',
  async (id) => {
    const response = await baseApi.get(`/experience/${id}`)
    return response.data.result
  }
)
export const deleteExperience = createAsyncThunk(
  'experiences/deleteExperience',
  async (id) => {
    const response = await baseApi.delete(`/experience/${id}`)
    return response.data.result
  }
)
export const addExperience = createAsyncThunk(
  'experiences/addExperience',
  async (values) => {
    const response = await baseApi.post(`/experience`, 
    {
      name: values.name,
      company: values.company,
      detail: values.detail,
      date: values.date,   
      category: values.category,      
    })
    return response.data.result
  }
)
export const updateExperience = createAsyncThunk(
  'experiences/updateExperience',
  async (values) => {
    const response = await baseApi.put(`/experience`, 
    {
      id: values.id,
      name: values.name,
      company: values.company,
      detail: values.detail,
      date: values.date,   
      category: values.category,      
    })
    return response.data.result
  }
)

export const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExperiences.pending, (state, action) => {
      state.experienceLoading = false
    });
    builder.addCase(fetchExperiences.fulfilled, (state, action) => {
      state.experiences = action.payload
      state.experienceLoading = true
      state.refreshData=false
    });
    builder.addCase(deleteExperience.pending, (state, action) => {
      state.experienceLoading = false
    });
    builder.addCase(deleteExperience.fulfilled, (state, action) => {
      state.experienceLoading = true
      state.refreshData=true
    });
    builder.addCase(addExperience.pending, (state, action) => {
      state.experienceLoading = false
    });
    builder.addCase(addExperience.fulfilled, (state, action) => {
      state.experienceLoading = true
      state.refreshData=true
    });
    builder.addCase(fetchExperienceById.pending, (state, action) => {
      state.updateLoading = false
    });
    builder.addCase(fetchExperienceById.fulfilled, (state, action) => {
      state.experience=action.payload
      state.updateLoading = true
    });
    builder.addCase(updateExperience.pending, (state, action) => {
      state.experienceLoading = false
    });
    builder.addCase(updateExperience.fulfilled, (state, action) => {
      state.experienceLoading = true
      state.refreshData=true
    });

  },
})

export default experienceSlice.reducer