import './layout.css'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link,Outlet } from "react-router-dom";
import React, {useState } from "react";
import { PcDisplay,PersonWorkspace,Laptop,PersonCircle,BookmarkPlus,Messenger} from 'react-bootstrap-icons';


function Sidebar() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleChange=()=>setShow(!show);
  return (
   <div className="WebAppAdmin">

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button class="navbar-toggler ms-1" onClick={handleChange} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
    <li class="nav-item">
        <Link class="nav-link" to="/admin">Adminler
        </Link>
      </li>
      <li class="nav-item">
        <Link class="nav-link" to="project">Projeler
        </Link>
      </li>
      <li class="nav-item">
        <Link class="nav-link" to="experience">Deneyimler
        </Link>
      </li>
      <li class="nav-item">
        <Link class="nav-link" to="projectCategory">Proje Kategorileri
        </Link>
      </li>
      <li class="nav-item">
        <Link class="nav-link" to="talent">Yetenekler
        </Link>
      </li>
      <li class="nav-item">
        <Link class="nav-link" to="message">Mesajlar
        </Link>
      </li>
    </ul>
    
  </div>
</nav>
      <Offcanvas show={show} onHide={handleClose} style={{ width: 300 }}>
        <Offcanvas.Body style={{backgroundColor:"black" }}>
          <header id="header" className='h-100 w-100'>
            <div class="d-flex flex-column h-100 " >
              <div class="profile">
            
                <h1 class="text-light">Admin Paneli</h1>
              </div>
              <hr />
              <nav id="navbar" class="nav-menu navbar-xl mt-5 "  >
                <ul style={{ textAlign: "center" }}>
                  <li><Link to="/admin" onClick={() => handleClose()} class="nav-link scrollto active"><PersonCircle className="me-1 ms-4" /> <span>Adminler</span></Link></li>
                  <hr />
                  <li><Link to="experience" onClick={() => handleClose()} class="nav-link scrollto"><PcDisplay className="me-1 ms-4" /><span>Deneyimler</span></Link></li>
                  <hr />
                  <li><Link to="project" onClick={() => handleClose()} class="nav-link scrollto"><Laptop className="me-1 ms-4" /><span>Projeler</span></Link></li>
                  <hr />
                  <li><Link to="projectCategory" onClick={() => handleClose()} class="nav-link scrollto"><BookmarkPlus className="me-1 ms-4" /><span>Proje Kategorileri</span></Link></li>
                  <hr />
                  <li><Link to="talent" onClick={() => handleClose()} class="nav-link scrollto"><BookmarkPlus className="me-1 ms-4" /><span>Yetenekler</span></Link></li>
                  <hr />
                  <li><Link to="message" onClick={() => handleClose()} class="nav-link scrollto"><Messenger className="me-1 ms-4" /><span>Mesajlar</span></Link></li>
                  <hr />
                </ul>
              </nav>
         
            </div>
          </header>
        </Offcanvas.Body>
      </Offcanvas>
      <div style={{marginTop:"1%"}}>
      <Outlet></Outlet>

      </div>
   
    </div>
  );
}

export default Sidebar;
