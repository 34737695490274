import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { fetchContact, deleteContact } from '../../../redux/slice/contactSlice';
import { XCircleFill } from 'react-bootstrap-icons';
import AdminLoading from '../../Animation/AdminLoading';

function ContactList() {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { contacts, contactLoading, refreshData } = useSelector((state) => ({
    ...state.contact,
  }));

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: 'Message',
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: 'İşlemler',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div style={{ display: 'flex'}}>
            <XCircleFill
              onClick={() => {
                const confirmBox = window.confirm(
                  'Gerçekten silmek istiyor musunuz?'
                );
                if (confirmBox === true) {
                  dispatch(deleteContact(row.id));
                }
              }}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchContact());
  }, []);

  if (refreshData) {
    dispatch(fetchContact());
  }
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);
    if (value.length) {
      updatedData = contacts.filter((item) => {
        const startsWith = item.name
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.name.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  return !contactLoading ? (
    <AdminLoading/>
  ) : (
    <div className='card'>
    <div className='card-header' >
    <div style={{display:'flex'}}>
          <input type='text' className='search-input' id='title' name='title' placeholder=' Ara' onChange={handleFilter} />
        </div>
      </div>
      <div className='react-dataTable'>
        <DataTable
          title='Mesaj Listesi'
          columns={columns}
          data={searchValue.length ? filteredData : contacts}
          pagination
        />
      </div>
      <div className='card-footer'>
      <div className='card-text mb-0'>
        <span className='fw-bold'>Toplam Kayıt:</span>{' '}
        <span> {contacts.length}</span>
      </div>
      </div>

    </div>
  );
}
export default ContactList;
