import React from 'react';

import { useState } from 'react';
import { addExperience } from '../../../redux/slice/experienceSlice';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ExperienceValidate } from '../../../validation/validation';
function AddExperience() {
  const [formModal, setFormModal] = useState(false);
  //const handleModalShow = () => setFormModal(!formModal);
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        name: '',
        company: '',
        detail: '',
        date: '',
        category: '',
      }}
      validationSchema={ExperienceValidate}
      onSubmit={(values) => {
        dispatch(addExperience(values));
      }}>
      {({ errors, touched, handleChange, handleSubmit }) => (
        <div className='ms-auto'>
          <button
            type='button'
            className='btn btn-info'
            onClick={() => setFormModal(!formModal)}
          >
            Deneyim Oluştur
          </button>

          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}>Deneyim Ekle</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="last-name-column">Deneyim Adı</label>
                          <input type="text" class="form-control" placeholder="Deneyim Adı" id='name' onChange={handleChange} />
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label">Şirket Adı</label>
                          <input type="text" class="form-control" placeholder="Şirket Adı" id='company' onChange={handleChange} />
                          {touched.company && errors.company && (<p>{errors.company}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label">Tarihler</label>
                          <input type="text" class="form-control" placeholder="Tarihler" id='date' onChange={handleChange} />
                          {touched.date && errors.date && (<p>{errors.date}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" >Kategori</label>
                          <select
                            name='category'
                            id='category'
                            class="form-select"
                            required
                            onChange={handleChange}
                          >
                            <option value=''>Kategori Seçiniz</option>
                            <option value='1'>İş Deneyimi</option>
                            <option value='2'>Projeler</option>
                          </select>
                          {touched.category && errors.category && (<p>{errors.category}</p>)}

                        </div>
                      </div>

                      <div class="col-12">
                        <div class="mb-2">
                          <label class="form-label" for="company-column">Detay</label>
                          <textarea type="text" class="form-control" placeholder="Detay" id='detail' onChange={handleChange} />
                          {touched.detail && errors.detail && (<p>{errors.detail}</p>)}
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Ekle</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default AddExperience;
