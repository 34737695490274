import React, { useEffect, useState } from "react";
import { useNavigate,Outlet } from "react-router-dom";
import baseApi from "../baseApi/baseApi";
import AdminLayout from "../components/Admin/AdminLayout";

export default function Protected() {
  const [check, setCheck] = useState("");
  const navigate = useNavigate();
  const checkCookie = async () => {
    await baseApi
    .post("/login/auth")
      .then((response) => {
        console.log(response)
        setCheck(response.data.auth);
      })
      .catch((err) => setCheck("false"));
  };
  checkCookie();

  if (check === "true") {
    return(
      <AdminLayout />
      
    );
  } else {
    navigate("/login");
  }

 
}
