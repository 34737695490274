import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import baseApi from "../../baseApi/baseApi"
const initialState = {
  projects: [],
  project:null,
  projectLoading: true,
  refreshData:false,
  updateLoading:true,
  errorMessage: "",
}

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async () => {
    const response = await baseApi.get(`/project/GetProjectWithCategory`)
    return response.data.result
  }
)
export const fetchProjectById = createAsyncThunk(
  'projects/fetchProjectById',
  async (id) => {
    const response = await baseApi.get(`/project/${id}`)
    return response.data.result
  }
)
export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (id) => {
    const response = await baseApi.delete(`/project/${id}`)
    return response.data.result
  }
)
export const addProject = createAsyncThunk(
  'projects/addProject',
  async (values) => {
    const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("detail", values.detail);
      formData.append("categoryId", values.categoryId);
      formData.append("link", values.link || "");
      formData.append("sequence", values.sequence);
      formData.append("image", values.image); // Resim dosyasını FormData'ya ekle
   
      const response = await baseApi.post(`/project`,formData)
    return response.data.result
  }
)
export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (values) => {
      const formData = new FormData();
      formData.append("id", values.id);
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("detail", values.detail);
      formData.append("categoryId", values.categoryId);
      formData.append("imgURL", values.imgURL);
      formData.append("link", values.link || "");
      formData.append("sequence", values.sequence);
      formData.append("image", values.image); // Resim dosyasını FormData'ya ekle
    const response = await baseApi.put(`/project`, formData)
    return response.data.result
  }
)

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state, action) => {
      state.projectLoading = false
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      state.projects = action.payload
      state.projectLoading = true
      state.refreshData=false
    });
    builder.addCase(deleteProject.pending, (state, action) => {
      state.projectLoading = false
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.projectLoading = true
      state.refreshData=true
    });
    builder.addCase(addProject.pending, (state, action) => {
      state.projectLoading = false
    });
    builder.addCase(addProject.fulfilled, (state, action) => {
      state.projectLoading = true
      state.refreshData=true
    });
    builder.addCase(fetchProjectById.pending, (state, action) => {
      state.updateLoading = false
    });
    builder.addCase(fetchProjectById.fulfilled, (state, action) => {
      state.project=action.payload
      state.updateLoading = true
    });
    builder.addCase(updateProject.pending, (state, action) => {
      state.projectLoading = false
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.projectLoading = true
      state.refreshData=true
    });

  },
})

export default projectSlice.reducer
