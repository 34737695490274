import React from 'react';
import { useState } from 'react';
import { addProjectCategory } from '../../../redux/slice/projectCategorySlice';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ProjectCategoryValidate } from '../../../validation/validation';

function ProjectCategory() {
  const [formModal, setFormModal] = useState(false);
  //const handleModalShow = () => setFormModal(!formModal);
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={ProjectCategoryValidate}
      onSubmit={(values) => {
        dispatch(addProjectCategory(values));
      }}
    >
      {({ errors, touched, handleChange, handleSubmit }) => (
        <div className='ms-auto'>
          <button
            type='button'
            className='btn btn-info'
            onClick={() => setFormModal(!formModal)}
          >
            Proje Kategorisi Oluştur
          </button>

          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}> Proje Kategorisi Ekle</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="last-name-column">Kategori Adı</label>
                          <input type="text" class="form-control" placeholder="Proje Adı"  id='name' onChange={handleChange} />
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Ekle</button>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default ProjectCategory;
