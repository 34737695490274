import React from 'react';
import AdminList from '../../../components/Admin/Admin/AdminList';

function Admin() {
  return (
    <AdminList /> 
  );
}

export default Admin;
