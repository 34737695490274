import React from 'react';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { fetchExperienceById, updateExperience } from '../../../redux/slice/experienceSlice';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { ExperienceValidate } from '../../../validation/validation';
import { PencilSquare } from 'react-bootstrap-icons';


function UpdateExperience({ id }) {
  const [formModal, setFormModal] = useState(false);
  const { experience, updateLoading } = useSelector((state) => ({ ...state.experience }));
  const dispatch = useDispatch();

  const handleClick = ({ id }) => {
    setFormModal(!formModal);
    dispatch(fetchExperienceById(id));
  };

  return !updateLoading ? null : (
    <Formik
      initialValues={{
        id: experience && experience.id,
        company: experience && experience.company,
        detail: experience && experience.detail,
        date: experience && experience.date,
        name: experience && experience.name,
        category: experience && experience.category
      }}
      validationSchema={ExperienceValidate}
      onSubmit={(values) => {
        dispatch(updateExperience(values));
      }}
    >
      {({ errors, touched, handleChange, handleSubmit }) => (
        <div>
          <PencilSquare  style={{verticalAlign:"baseline"}} onClick={() => handleClick({ id })} />
          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}>Deneyim Güncelle</ModalHeader>
            <ModalBody>
            <Form onSubmit={handleSubmit}>
            <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="last-name-column">Deneyim Adı</label>
                          <input type="text" class="form-control" placeholder="Deneyim Adı" id='name' 
                          onChange={handleChange} defaultValue={experience && experience.name}/>
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label">Şirket Adı</label>
                          <input type="text" class="form-control" placeholder="Şirket Adı" id='company'
                           onChange={handleChange} defaultValue={experience && experience.company}/>
                          {touched.company && errors.company && (<p>{errors.company}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label">Tarihler</label>
                          <input type="text" class="form-control" placeholder="Tarihler" id='date' 
                          onChange={handleChange} defaultValue={experience && experience.date}/>
                          {touched.date && errors.date && (<p>{errors.date}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" >Kategori</label>
                          <select
                            name='category'
                            id='category'
                            class="form-select"
                            required
                            onChange={handleChange}
                            defaultValue={experience && experience.category}
                          >
                            <option value=''>Kategori Seçiniz</option>
                            <option value='1'>İş Deneyimi</option>
                            <option value='2'>Projeler</option>
                          </select>
                          {touched.category && errors.category && (<p>{errors.category}</p>)}

                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-2">
                          <label class="form-label" for="company-column">Detay</label>
                          <textarea type="text" class="form-control" placeholder="Detay" id='detail' 
                          onChange={handleChange} defaultValue={experience && experience.detail}/>
                          {touched.detail && errors.detail && (<p>{errors.detail}</p>)}
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Güncelle</button>
                      </div>
                    </div>
                  </div>
                </div>
            </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default UpdateExperience;
