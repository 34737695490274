import Lottie from "lottie-react";
import groovyWalkAnimation from "./dev2.json";
const style = {
  height:"70vh",
};
const LottieAnimation = () => {
  
  return <Lottie animationData={groovyWalkAnimation}  style={style} />;
};

export default LottieAnimation;