import * as Yup from 'yup';

export const ProjectValidate = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Bu alan gereklidir'),
    company: Yup.string().required('Bu alan gereklidir'),
    detail: Yup.string().required('Bu alan gereklidir'),
    date: Yup.string().required('Bu alan gereklidir'),
    categoryId: Yup.string().required('Bu alan gereklidir'),
});

export const AdminValidate = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Bu alan gereklidir'),
    surname: Yup.string().required('Bu alan gereklidir'),
    username: Yup.string().required('Bu alan gereklidir'),
    password: Yup.string()
    .required('Bu alan gereklidir')
    .min(2, 'Too Short!'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Şifreler Eşleşmiyor')
    .required('Bu alan gereklidir')
});

export const ProjectCategoryValidate = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Too Long!')
    .required('Bu alan gereklidir'),
});

export const ExperienceValidate = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Bu alan gereklidir'),
    company: Yup.string().required('Bu alan gereklidir'),
    detail: Yup.string().required('Bu alan gereklidir'),
    date: Yup.string().required('Bu alan gereklidir'),
});
export const TalentValidate = Yup.object().shape({
  name: Yup.string()
    .max(50, 'Too Long!')
    .required('Bu alan gereklidir'),
  percent: Yup.number().required('Bu alan gereklidir'),

});

export const ContactValidate = Yup.object().shape({
  name: Yup.string()
    .required('Lütfen isminizi giriniz'),
    email: Yup.string()
    .email('Geçerli bir e-posta adresi girin')
    .required('Lütfen mail adresinizi giriniz'),     // E-posta formatını doğrula
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Geçerli bir telefon numarası girin') // Sadece rakam kabul et
    .min(10, 'Telefon numarası çok kısa')
    .max(15, 'Telefon numarası çok uzun'),
    message: Yup.string().required('Lütfen mesajınızı giriniz'),
});