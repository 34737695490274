import "./About.css"
import userImg from '../../assets/images/admin.jpeg';
import cv from '../../assets/oguzhancv.pdf';
import Footer from '../../components/Footer/Footer';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchExperiences } from '../../redux/slice/experienceSlice';
import { fetchTalents } from '../../redux/slice/talentSlice';
import AdminLoading from '../../components/Animation/AdminLoading';

function About() {
  const dispatch = useDispatch();
  const { experiences, experienceLoading } = useSelector((state) => ({
    ...state.experience,
  }));  
  const { talents,talentLoading } = useSelector((state) => ({
    ...state.talent,
  }));
  useEffect(() => {
    dispatch(fetchTalents());
    dispatch(fetchExperiences());
  }, []);

  const handleClick = () => {
    window.open(cv, '_blank');
  };
  return (
    <>

      <div className='App-home'>
        <div className="container page-title text-center mb-3">
          <h2 className=" phoneText">
            Hakkımda
          </h2>
        </div>
        <div className="container infos">
          <div className="row personal-info">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="image-container mb-5">
                <img
                  className="img-fluid d-block"
                  src={userImg}
                  alt="User Image"
                />
              </div>
            </div>
            <div className="row col-xl-6 col-lg-6 col-md-12">

              <div className="col-12 resume-btn-container">
                <p className="d-block ">
                Merhaba ben Oğuzhan Çınar,
                <br/>
                <br/>
                Eğitimimi 2023 yılında, Bilgisayar Mühendisliği dalında Erciyes Üniversitesi'nde başarıyla tamamladım. 
                Bu süre zarfında, TÜBİTAK 2209-A, TÜBİTAK BiGG ve Benim İşim Girişim gibi seçkin yarışmalarda proje destekleri elde ederek proje geliştirme deneyimini kazandım.
                Ayrıca, Teknofest yarışmasında finale yükselerek yeni ve değerli tecrübeler edindim.
                <br/>
                <br/>
                Eğitim hayatım boyunca Visseria A.Ş'de TÜBİTAK bursiyeri olarak görev aldım 
                ve aynı zamanda Çemrek Bilgi Teknolojileri ile Aksaray Çizgi Mühendislik gibi şirketlerde çalışarak 
                sektör deneyimi edindim. 
                <br/>
                <br/>
                Yapay Zeka, Bilgi Teknolojisi ve Güvenliği Kulübü'nde eğitim birim başkanı olarak aktif rol üstlendim. 
                Aldığım bu sorumluluklar,müşteri memnuniyetinin ne kadar önemli olduğunu benimsememi sağladı. Aynı zamanda, etkili takım çalışmasının gücünü deneyimleyerek işbirliği ve uyumun getirdiği değeri gördüm. Bu deneyimlerim, sorunlara yönelik çözüm odaklı yaklaşımımı daha da güçlendirdi, böylece her zorlukla başa çıkma yeteneğimi en üst düzeye çıkardım.
                </p>
              </div>
              <div className="col-12 resume-btn-container">
                <a className="btn btn-resume" onClick={handleClick}>
                  <span >
                    <i className="fa fa-download" />
                    Özgeçmişi görüntüle
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container col-12 mx-auto text-center">
          <hr className="about-section" />
        </div>
        {!experienceLoading ?  <AdminLoading />:(<div className="container">
          
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6">
              <h2 className="font-weight-600 uppercase title-section mb-4 mt-3">
                Deneyimler
              </h2>
              <div className="resume-items">
                {experiences.filter((item)=>item.category==1).map((exp, index) => (
                  <div className="item">
                    <span className="bullet" />
                    <div className="card">
                      <div className="card-header">
                        <span className="year">
                          <i className="fa fa-calendar" />
                          <i className="fa fa-caret-right" />
                          {exp.date}
                        </span>
                        <span className="d-block font-weight-400 ">
                          {exp.name}
                          <span className="separator" />
                          <span className="font-weight-700">{exp.company}</span>
                        </span>
                      </div>
                      <div className="card-body">
                        <p>
                          {exp.detail}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 skills-container">
              <h2 className="font-weight-600 uppercase title-section mb-4 mt-3">
                Projeler
              </h2>
              <div className="resume-items">
              {experiences.filter((item)=>item.category==2).map((exp, index) => (
                  <div className="item">
                    <span className="bullet" />
                    <div className="card">
                      <div className="card-header">
                        <span className="year">
                          <i className="fa fa-calendar" />
                          <i className="fa fa-caret-right" />
                          {exp.date}
                        </span>
                        <span className="d-block font-weight-400 ">
                          {exp.name}
                          <span className="separator" />
                          <span className="font-weight-700">{exp.company}</span>
                        </span>
                      </div>
                      <div className="card-body">
                        <p>
                          {exp.detail}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>)}
        
        <div className="container mt-5">
          <hr />
        </div>
        {!talentLoading ?  <AdminLoading />:(
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="font-weight-600 uppercase title-section skills-title mb-5">
                Yetenekler
              </h2>
            </div>
            {talents.map((talent, index) => (
            <div className="col-12 col-sm-6 col-md-4">
              <span className="skill-text">{talent.name}</span>
              <div className="chart-bar">
                <span
                  className="item-progress"
                  data-percent={talent.percent}
                  style={{ width: `${talent.percent}%` }}
                />
                <span className="percent" style={{ right: `calc(${100 - talent.percent}% - 21px)` }}>
                  {talent.percent}%
                  <b className="arrow" />
                </span>
              </div>
            </div>))}
            
          </div>
        </div>)}
        
      </div>
      <Footer />
    </>

  );
}

export default About;

