//Hata alınca çalışacak olan kısım
function Error() {
  return (
  <div className="App-home">
    
  <div className="m-auto">
  <h1>Böyle bir sayfa yok :)</h1>

  </div>
  
  </div>
  );
}

export default Error;
