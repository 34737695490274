import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Layout from '../pages/Layout/Layout';
import Error from '../pages/Error/Error';
import Project from '../pages/Project/Project';
import Contact from '../pages/Contact/Contact';
import Login from '../pages/Login/Login';
import Protected from '../navigation/Protected';
import Admin from '../pages/Admin/Admin/Admin';
import AdminExperience from '../pages/Admin/Experience/Experience';
import AdminProject from '../pages/Admin/Project/Project';
import AdminProjectCategory from '../pages/Admin/ProjectCategory/ProjectCategory';
import AdminTalent from '../pages/Admin/Talent/Talent';
import AdminMessage from '../pages/Admin/Contact/Contact';


function Navigation() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route  path="/projects" element={<Project />} />
          <Route  path="/contact" element={<Contact />} />

        </Route>
        <Route path='/login' element={<Login />} />
        <Route path="admin/" element={<Protected />}>
        <Route path='experience' element={<AdminExperience />} />
        <Route path='project' element={<AdminProject />} />
        <Route path='projectCategory' element={<AdminProjectCategory />} />
        <Route path='talent' element={<AdminTalent />} />
        <Route path='message' element={<AdminMessage />} />

        <Route index element={<Admin />} />
        </Route>
        <Route path="/*" element={<Error />} />

      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;