import { configureStore } from "@reduxjs/toolkit";

import adminSlice from "./slice/adminSlice";
import projectSlice from "./slice/projectSlice";
import experienceSlice from "./slice/experienceSlice";
import talentSlice from "./slice/talentSlice";
import projectCategorySlice from "./slice/projectCategorySlice";
import contactSlice from "./slice/contactSlice";


const store = configureStore({
    reducer: {
        admin: adminSlice,
        project: projectSlice,
        experience: experienceSlice,
        projectCategory:projectCategorySlice,
        talent:talentSlice,
        contact:contactSlice

    }
});

export default store;