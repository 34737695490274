import React from 'react';
import { useState } from 'react';
import { fetchProjectById, updateProject } from '../../../redux/slice/projectSlice';
import { fetchProjectCategories } from '../../../redux/slice/projectCategorySlice';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { PencilSquare } from 'react-bootstrap-icons';
// import { ProjectValidate } from '../../../validation/validation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function Updateproject({ id }) {
  const [formModal, setFormModal] = useState(false);
  const { project, updateLoading } = useSelector((state) => ({ ...state.project }));
  const dispatch = useDispatch();
  const { projectCategories } = useSelector((state) => ({
    ...state.projectCategory,
  }));
  const handleClick = ({ id }) => {
    setFormModal(!formModal);
    dispatch(fetchProjectCategories());
    dispatch(fetchProjectById(id));
  };

  return !updateLoading ? null : (
    <Formik
      initialValues={{
        id: project && project.id,
        name: project && project.name,
        description: project && project.description,
        detail: project && project.detail,
        categoryId: project && project.categoryId,
        imgURL: project && project.imgURL,
        link: project && project.link,
        sequence: project && project.sequence,
        image: null,
      }}
      // validationSchema={ProjectValidate}
      onSubmit={(values) => {
       console.log(values)
        dispatch(updateProject(values));
      }}
    >
      {({ errors, touched, handleChange, handleSubmit, setFieldValue }) => (
        <div>
          <PencilSquare style={{ verticalAlign: "baseline" }} onClick={() => handleClick({ id })} />
          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}>Deneyim Güncelle</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="last-name-column">Proje Adı</label>
                          <input type="text" class="form-control" placeholder="Proje Adı" id='name'
                            onChange={handleChange} defaultValue={project && project.name} />
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" >Kategori</label>
                          <select
                            name='categoryId'
                            id='categoryId'
                            class="form-select"
                            required
                            onChange={handleChange}
                            defaultValue={project && project.categoryId}
                          >
                            <option value=''>Kategori Seçiniz</option>
                            {projectCategories.map((data, index) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </select>
                          {touched.categoryId && errors.categoryId && (<p>{errors.categoryId}</p>)}

                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-2">
                          <label class="form-label">Tanım</label>
                          <textarea type="text" class="form-control" placeholder="Tanım" id='description'
                            onChange={handleChange} defaultValue={project && project.description} />
                          {touched.description && errors.description && (<p>{errors.description}</p>)}
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-2">
                          <label class="form-label" for="company-column">Detay</label>
                          <ReactQuill
                            id="detail"
                            defaultValue={project && project.detail}
                            onChange={(value) => setFieldValue('detail', value)}
                          />  {touched.detail && errors.detail && (<p>{errors.detail}</p>)}
                          {touched.detail && errors.detail && (<p>{errors.detail}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="email-id-column">Link</label>
                          <input type="text" class="form-control" placeholder="Link" id='link'
                            onChange={handleChange} defaultValue={project && project.link} />
                          {touched.link && errors.link && (<p>{errors.link}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="email-id-column">Sıra</label>
                          <input type="text" class="form-control" placeholder="Sıra" id='sequence'
                            onChange={handleChange} defaultValue={project && project.sequence} />
                          {touched.sequence && errors.sequence && (<p>{errors.sequence}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div className="mb-2">
                          <label className="form-label" htmlFor="image">
                            Resim Seçin
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="image"
                            onChange={(event) => {
                              const selectedImage = event.target.files[0];
                              handleChange(event);
                              setFieldValue("image", selectedImage);
                            }}
                          />
                          {touched.image && errors.image && <p>{errors.image}</p>}
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Güncelle</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default Updateproject;
