import React from 'react';
import TalentList from '../../../components/Admin/Talent/TalentList';

function Talent() {
  return (
    <TalentList /> 
  );
}

export default Talent;
