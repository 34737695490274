import React from 'react';
import ProjectList from '../../../components/Admin/Project/ProjectList';

function Project() {
  return (
    <ProjectList /> 
  );
}

export default Project;
