import MovingComponent from "react-moving-text";
const AnimationText = () => {
  return (
    <div className="ah-headline d-flex">
<h1 className=" mx-auto phoneAnimationText">
      <MovingComponent
        type="typewriter"
        dataText={[
          "Hoş Geldiniz",
          "Lütfen Giriş Yapınız",
        ]}
      />
    </h1>
    </div>
  );
};
export default AnimationText;
