import AnimationText from "../../components/MovingText/AnimationText";
import LottieAnimation from '../../components/Animation/LottieAnimation';
import "./Home.css"
function Home() {

  
  //Brush Script MT ,Gabriola ,Lucida Handwriting
  return (
    <div className="homeImg">
      <div className='App-home'>
        <div className="main-text mt-5" id="selector">
          <AnimationText />
          <LottieAnimation />
         
        </div>
      </div>
    </div>

  );
}

export default Home;