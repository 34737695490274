import './layout.css'
import '../../App.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import React, {useState } from "react";
import { HouseFill,PersonWorkspace,Laptop,Github,Linkedin,Instagram,List,ChatDots } from 'react-bootstrap-icons';
import { Outlet } from 'react-router-dom';
import userImage from "../../assets/images/user.jpeg"

//Layout sayfamız
function Layout() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleChange=()=>setShow(!show);
  return (
   <div className="WebApp">
    <div>
      <div className="starsec"></div>
      <div className="starthird"></div>
      <div className="starfourth"></div>
      <div className="starfifth"></div>
    </div>
      <Offcanvas show={show} onHide={handleClose} style={{ width: 300 }}>
        <Offcanvas.Body style={{backgroundColor:"black" }}>
          <header id="header" className='h-100 w-100'>
            <div className="d-flex flex-column h-100 " >
              <div className="profile">
                <div className='d-flex'>
                  <img src={userImage} alt="" className="img-fluid rounded-circle" />
                </div>
                <h1 className="text-light">Oğuzhan Çınar</h1>
              </div>
              <hr />
              <nav id="navbar" className="nav-menu navbar-xl mt-5 "  >
                <ul style={{ textAlign: "center" }}>
                  <li><Link to="/" onClick={() => handleClose()} className="nav-link scrollto active"><HouseFill className="me-1 ms-4" /> <span>Ana Sayfa</span></Link></li>
                  <hr />
                  <li><Link to="/about" onClick={() => handleClose()} className="nav-link scrollto"><PersonWorkspace className="me-1 ms-4" /><span>Hakkımda</span></Link></li>
                  <hr />
                  <li><Link to="/projects" onClick={() => handleClose()} className="nav-link scrollto"><Laptop className="me-1 ms-4" /><span>Projeler</span></Link></li>
                  <hr />
                  <li><Link to="/contact" onClick={() => handleClose()} className="nav-link scrollto"><ChatDots className="me-1 ms-4" /><span>İletişim</span></Link></li>

                </ul>
              </nav>
              <div className="profile mt-auto mb-4" >
                <div className="social-links mt-3 text-center" >
                  <a style={{ backgroundColor: "white", color: "#040b14" }} href="https://github.com/OguzhanCnr"className="twitter"><Github/></a>
                  <a style={{ backgroundColor: "white", color: "#040b14" }} href="https://www.instagram.com/ogzhn.cnrr/" className="instagram"><Instagram/></a>
                  <a style={{ backgroundColor: "white", color: "#040b14" }} href="https://www.linkedin.com/in/ogzhncinar/" className="linkedin"><Linkedin /></a>
             
                </div>
              </div>
            </div>
          </header>
        </Offcanvas.Body>
      </Offcanvas>
      <Button style={{height:50,width:50}} className="listButton" onClick={handleChange}><List style={{fontSize:"25px"}}/></Button>
      <Outlet></Outlet>
   
    </div>
  );
}

export default Layout;
