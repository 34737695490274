import React from 'react';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { fetchProjectCategoryById, updateProjectCategory } from '../../../redux/slice/projectCategorySlice';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { PencilSquare } from 'react-bootstrap-icons';
import { ProjectCategoryValidate } from '../../../validation/validation';


function UpdateProjectCategory({ id }) {
  const [formModal, setFormModal] = useState(false);
  const { projectCategory, updateLoading } = useSelector((state) => ({ ...state.projectCategory }));
  const dispatch = useDispatch();

  const handleClick = ({ id }) => {
    setFormModal(!formModal);
    dispatch(fetchProjectCategoryById(id));
  };

  return !updateLoading ? null : (
    <Formik
      initialValues={{
        id: projectCategory && projectCategory.id,
        company: projectCategory && projectCategory.company,
        detail: projectCategory && projectCategory.detail,
        date: projectCategory && projectCategory.date,
        name: projectCategory && projectCategory.name,
      }}
      validationSchema={ProjectCategoryValidate}
      onSubmit={(values) => {
        dispatch(updateProjectCategory(values));
      }}
    >
      {({ errors, touched, handleChange, handleSubmit }) => (
        <div>
          <PencilSquare style={{ verticalAlign: "baseline" }} onClick={() => handleClick({ id })} />
          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}>Deneyim Güncelle</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="last-name-column">Kategori Adı</label>
                          <input type="text" class="form-control" placeholder="Proje Adı" id='name'
                            onChange={handleChange} defaultValue={projectCategory && projectCategory.name} />
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Güncelle</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default UpdateProjectCategory;
