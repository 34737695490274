import "./Project.css";
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjects } from '../../redux/slice/projectSlice';
import Footer from '../../components/Footer/Footer';
import ProjectModal from "../../components/Modal/ProjectDetail"
import AdminLoading from '../../components/Animation/AdminLoading';
import ReactPaginate from 'react-paginate';
import { PhoneFill, Globe, Robot } from 'react-bootstrap-icons';
import { useMemo } from 'react';

function Project() {
  const [showModal, setShowModal] = useState(false);
  const [projectDetail, setProjectDetail] = useState([]);
  const dispatch = useDispatch();
  const { projects, projectLoading } = useSelector((state) => ({
    ...state.project,
  }));
  const [selectedCategory, setSelectedCategory] = useState("Web");
  const [currentPage, setCurrentPage] = useState(0); // İlk sayfa
  const projectsPerPage = 5; // Sayfa başına gösterilecek proje sayısı
  const handleModal = (projects) => {
    setProjectDetail(projects);
    setShowModal(true);
  }
  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);


  const filteredProjects = useMemo(() => {
    return projects.filter((project) => project.categoryName === selectedCategory);
  }, [projects, selectedCategory]);

  const pageCount = Math.ceil(filteredProjects.length / projectsPerPage);

  const offset = currentPage * projectsPerPage;
  const currentProjects = projects
    .filter((project) => project.categoryName === selectedCategory)
    .slice(offset, offset + projectsPerPage);

  // Sayfa değiştikçe bu işlevi çağırma
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e);
    setCurrentPage(0); // Kategori değiştiğinde ilk sayfaya dön
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="container page-title text-center mt-2">
            <h2 className="text-center phoneText"><span>Projeler</span></h2>
          </div>

          <div className="category-buttons text-center">
            {/* Buttons for Small Screens */}
            <div className="small-screen-buttons">
              <button className={`category-button ${selectedCategory === "Web" && "active" } three-d-button` } onClick={() => handleCategoryChange("Web")}>
                <Globe size={30} />
              </button>
              <button className={`category-button ${selectedCategory === "Mobil" && "active"} three-d-button`} onClick={() => handleCategoryChange("Mobil")}>
                <PhoneFill size={30} />
              </button>
              <button className={`category-button ${selectedCategory === "AI" && "active"} three-d-button`} onClick={() => handleCategoryChange("AI")}>
                <Robot size={30} />
              </button>
            </div>

            {/* Buttons for Web */}
            <div className="web-buttons">
              <button className="three-d-button" onClick={() => handleCategoryChange("Web")}>
                <Globe size={24} ></Globe > Web Uygulamaları
              </button>
              <button className="three-d-button" onClick={() => handleCategoryChange("Mobil")}>
                <PhoneFill size={24}></PhoneFill> Mobil Uygulamalar
              </button>
              <button className="three-d-button" onClick={() => handleCategoryChange("AI")}>
                <Robot size={24} ></Robot  > Yapay Zekâ Uygulamaları
              </button>
            </div>
          </div>

          <h2 className="mb-3 mt-5" style={{ color: "#2196f3" }}><span>{selectedCategory} Uygulamalar</span></h2>
          <hr />

          {!projectLoading ? (
            <AdminLoading />
          ) : (
            currentProjects.map((project, index) => (
              <div className="col-12 col-md-6 col-lg-6" key={index}>
                <ProjectModal projects={projectDetail} showModal={showModal} setShowModal={setShowModal}></ProjectModal>
                <div className="card text-white bg-dark mb-3" style={{ maxWidth: 500 }}>
                  <img className="img" src={project.imgURL} onClick={() => handleModal(project)} alt="Card image cap" />
                  <div className="card-body projectCardBody">
                    <h5 className="card-title">{project.name}</h5>
                    <p className="card-text">{project.description}</p>
                  </div>
                </div>
              </div>
            ))
          )}

          {/* Sayfalama */}
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={"paggination-buttons"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


export default Project;
