import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import baseApi from "../../baseApi/baseApi"
const initialState = {
  talents: [],
  talent:null,
  talentLoading: true,
  refreshData:false,
  updateLoading:true,
  errorMessage: "",
}

export const fetchTalents = createAsyncThunk(
  'talent/fetchTalents',
  async () => {
    const response = await baseApi.get(`/talent`)
    return response.data.result
  }
)
export const fetchTalentById = createAsyncThunk(
  'talent/fetchTalentById',
  async (id) => {
    const response = await baseApi.get(`/talent/${id}`)
    return response.data.result
  }
)
export const deleteTalent = createAsyncThunk(
  'talent/deleteTalent',
  async (id) => {
    const response = await baseApi.delete(`/talent/${id}`)
    return response.data.result
  }
)
export const addTalent = createAsyncThunk(
  'talent/addTalent',
  async (values) => {
    const response = await baseApi.post(`/talent`, 
    {
      name: values.name,
      percent: values.percent    

    })
    return response.data.result
  }
)
export const updateTalent = createAsyncThunk(
  'talent/updateTalent',
  async (values) => {
    const response = await baseApi.put(`/talent`, 
    {
      id: values.id,
      name: values.name,
      percent: values.percent    

    })
    return response.data.result
  }
)

export const talentSlice = createSlice({
  name: 'talent',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTalents.pending, (state, action) => {
      state.talentLoading = false
    });
    builder.addCase(fetchTalents.fulfilled, (state, action) => {
      state.talents = action.payload
      state.talentLoading = true
      state.refreshData=false
    });
    builder.addCase(deleteTalent.pending, (state, action) => {
      state.talentLoading = false
    });
    builder.addCase(deleteTalent.fulfilled, (state, action) => {
      state.talentLoading = true
      state.refreshData=true
    });
    builder.addCase(addTalent.pending, (state, action) => {
      state.talentLoading = false
    });
    builder.addCase(addTalent.fulfilled, (state, action) => {
      state.talentLoading = true
      state.refreshData=true
    });
    builder.addCase(fetchTalentById.pending, (state, action) => {
      state.updateLoading = false
    });
    builder.addCase(fetchTalentById.fulfilled, (state, action) => {
      state.talent=action.payload
      state.updateLoading = true
    });
    builder.addCase(updateTalent.pending, (state, action) => {
      state.talentLoading = false
    });
    builder.addCase(updateTalent.fulfilled, (state, action) => {
      state.talentLoading = true
      state.refreshData=true
    });

  },
})

export default talentSlice.reducer