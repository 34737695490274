import AnimationText from "../../components/MovingText/AnimationTextLogin";
import './login.css';

import baseApi from '../../baseApi/baseApi';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
function Login() {
  const navigate = useNavigate();
  const sendDataToAPI = values => {
    baseApi.post(`/Login?Username=${values.username}&Password=${values.password}`)
      .then((getData) => {
        localStorage.setItem("token", getData.data.token)
        navigate("/admin");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className="body d-flex">
      <div>
        <div className="starsec"></div>
        <div className="starthird"></div>
        <div className="starfourth"></div>
        <div className="starfifth"></div>
      </div>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        onSubmit={values => {
          sendDataToAPI(values);
        }}
      >
        {({ errors, touched, handleChange, handleSubmit }) => (
          <div className="container my-auto">
            <div className="row justify-content-center align-items-center">
              <div className="col-md-6 text-center mb-5">
                <h2 className="heading-section"><AnimationText></AnimationText></h2>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-lg-4">
                <div class="login-wrap p-0">
                  <form onSubmit={handleSubmit} class="signin-form">
                    <div class="form-group">
                      <input type="text" id="username" name="username" class="form-control form-control-login" placeholder="Username" onChange={handleChange} required="" />
                    </div>
                    <div class="form-group">
                      <input id="password" name="password" type="password" class="form-control form-control-login" placeholder="Password" onChange={handleChange} required="" />
                      <span toggle="password" class="fa fa-fw field-icon toggle-password fa-eye"></span>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="form-control form-control-login btn btn-primary  px-3 " style={{ opacity: 0.9 }}>Sign In</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default Login;