import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import baseApi from "../../baseApi/baseApi"
import { toast } from 'react-toastify';
const showNotification = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000, // Toast'un otomatik kapanma süresi (ms cinsinden)
  });
};
const initialState = {
  contacts: [],
  contact:null,
  contactLoading: true,
  refreshData:false,
  updateLoading:true,
  errorMessage: "",
}

export const fetchContact = createAsyncThunk(
  'contacts/fetchContact',
  async () => {
    const response = await baseApi.get(`/contact`)
    return response.data.result
  }
)
export const fetchContactById = createAsyncThunk(
  'contacts/fetchContactById',
  async (id) => {
    const response = await baseApi.get(`/contact/${id}`)
    return response.data.result
  }
)
export const deleteContact = createAsyncThunk(
  'contacts/deleteContact',
  async (id) => {
    const response = await baseApi.delete(`/contact/${id}`)
    return response.data.result
  }
)
export const addContact = createAsyncThunk(
  'contacts/addContact',
  async (values) => {
    const response = await baseApi.post(`/contact`, 
    {
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message
    })
    return response.data.result
  }
)
export const updateContact = createAsyncThunk(
  'contacts/updateContact',
  async (values) => {
    const response = await baseApi.put(`/contact`, 
    {
      id: values.id,
      name: values.name,
      email: values.email,
      phone: values.phone,
      message: values.message
    })
    
    return response.data.result
  }
)
export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContact.pending, (state, action) => {
      state.contactLoading = false
    });
    builder.addCase(fetchContact.fulfilled, (state, action) => {
      state.contacts = action.payload
      state.contactLoading = true
      state.refreshData=false
    });
    builder.addCase(deleteContact.pending, (state, action) => {
      state.contactLoading = false
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.contactLoading = true
      state.refreshData=true
    });
    builder.addCase(addContact.pending, (state, action) => {
      state.contactLoading = false
      showNotification("Mesajınız gönderiliyor")
    });
    builder.addCase(addContact.fulfilled, (state, action) => {
      state.contactLoading = true
      state.refreshData=true
      showNotification("Mesajınız başarılı şekilde gönderildi")
    });
    builder.addCase(fetchContactById.pending, (state, action) => {
      state.updateLoading = false
    });
    builder.addCase(fetchContactById.fulfilled, (state, action) => {
      state.contact=action.payload
      state.updateLoading = true
      
    });
    builder.addCase(updateContact.pending, (state, action) => {
      state.contactLoading = false
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.contactLoading = true
      state.refreshData=true
    });

  },
})

export default contactSlice.reducer