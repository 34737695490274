import "./Contact.css"
import { useDispatch } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import { Formik, Form } from 'formik';
import { addContact } from '../../redux/slice/contactSlice';
import { TelephoneFill, EnvelopeFill, PersonFill, Github, Instagram, Linkedin } from 'react-bootstrap-icons';
import ContactAnimation from '../../components/Animation/ContactAnimation';
import { ContactValidate } from '../../validation/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Project() {
  const dispatch = useDispatch();
  return (
    <>
      <div className="container"> <div className="row">
        <div class="container page-title text-center mt-4 "><h2 class="text-center phoneText"><span>İletişim</span></h2></div>
        <hr />
        <div className="row">
          <div className="col-md-3 d-flex flex-column text-white p-5">
            <div className="contact-info">
              <div className="contact-item mb-3">
                <h4 className="contact-label">Ad-Soyad</h4>
                <div className="d-flex">
                  <PersonFill size={20} style={{ color: "#2196f3" }} className="contact-icon me-2" />
                  <p className="contact-value">Oğuzhan Çınar</p>
                </div>
              </div>
              <div className="contact-item mb-3">
                <h4 className="contact-label">Telefon</h4>
                <div className="d-flex">
                  <TelephoneFill size={20} style={{ color: "#2196f3" }} className="contact-icon me-2" />
                  <p className="contact-value">0539 207 70 59</p>
                </div>
              </div>
              <div className="contact-item mb-3">
                <h4 className="contact-label">E-posta</h4>
                <div className="d-flex">
                  <EnvelopeFill size={20} style={{ color: "#2196f3" }} className="contact-icon me-2" />
                  <p className="contact-value">ogzhn.cnr@hotmail.com</p>
                </div>
              </div>
            </div>
            <div class="contact mb-4" >
              <div class="social-links mt-3 " >
                <a style={{ backgroundColor: "#363535", color: "white" }} href="https://github.com/OguzhanCnr" class="twitter"><Github /></a>
                <a style={{ backgroundColor: "#363535", color: "white" }} href="https://www.instagram.com/ogzhn.cnrr/" class="instagram"><Instagram /></a>
                <a style={{ backgroundColor: "#363535", color: "white" }} href="https://www.linkedin.com/in/ogzhncinar/" class="linkedin"><Linkedin /></a>
              </div>
            </div>
          </div>

          <div className="col-md-4 ">
            <ContactAnimation />
          </div>

          <div className="col-md-5  text-white">
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                message: ''
              }}
              validationSchema={ContactValidate}
              onSubmit={(values) => {
             dispatch(addContact(values));
              }}
            >
              {({ errors, touched, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="form-group mb-3">
                    <label htmlFor="name" style={{ color: "white" }}>İsim</label>
                    <input type="text" className="form-control" id="name" style={{ backgroundColor: "#363535", color: "white" }} onChange={handleChange} />
                    {touched.name && errors.name && (<p>{errors.name}</p>)}

                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="email" style={{ color: "white" }}>Email</label>
                    <input type="email" className="form-control" id="email" style={{ backgroundColor: "#363535", color: "white" }} onChange={handleChange} />
                    {touched.email && errors.email && (<p>{errors.email}</p>)}

                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="phone" style={{ color: "white" }}>Telefon</label>
                    <input type="tel" className="form-control" id="phone" style={{ backgroundColor: "#363535", color: "white" }} onChange={handleChange} />
                    {touched.phone && errors.phone && (<p>{errors.phone}</p>)}

                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="message" style={{ color: "white" }}>Mesaj</label>
                    <textarea className="form-control" id="message" rows={4} style={{ backgroundColor: "#363535", color: "white" }} onChange={handleChange}></textarea>
                    {touched.message && errors.message && (<p>{errors.message}</p>)}
                  </div>
                  <button className="btn btn-primary" style={{ backgroundColor: "#2196f3" }}>Mesaj Gönder</button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      </div>
      <ToastContainer/>
      <Footer />
    </>
  );
}

export default Project;
