import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import AddTalent from './AddTalent';
import UpdateTalent from './UpdateTalent';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTalents, deleteTalent } from '../../../redux/slice/talentSlice';
import { XCircleFill } from 'react-bootstrap-icons';
import AdminLoading from '../../Animation/AdminLoading';

function TalentList() {
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const dispatch = useDispatch();
  const { talents, talentLoading, refreshData } = useSelector((state) => ({
    ...state.talent,
  }));

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
     {
      name: 'Oran',
      selector: (row) => row.percent,
      sortable: true,
    },
    {
      name: 'İşlemler',
      allowOverflow: true,
      cell: (row) => {
        return (
          <div style={{ display: 'flex' }}>
            <XCircleFill
              onClick={() => {
                const confirmBox = window.confirm(
                  'Gerçekten silmek istiyor musunuz?'
                );
                if (confirmBox === true) {
                  dispatch(deleteTalent(row.id));
                }
              }}
            />
            <div style={{ marginLeft: 5 }}>
              <UpdateTalent id={row.id}> </UpdateTalent>
            </div>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchTalents());
  }, []);

  if (refreshData) {
    dispatch(fetchTalents());
  }
  const handleFilter = (e) => {
    const value = e.target.value;
    let updatedData = [];
    setSearchValue(value);
    if (value.length) {
      updatedData = talents.filter((item) => {
        const startsWith = item.name
          .toLowerCase()
          .startsWith(value.toLowerCase());
        const includes = item.name.toLowerCase().includes(value.toLowerCase());
        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });
      setFilteredData(updatedData);
      setSearchValue(value);
    }
  };

  return !talentLoading ? (
    <AdminLoading />
  ) : (
    <div className='card'>
      <div className='card-header' >
        <div style={{ display: 'flex' }}>
          <input type='text' className='search-input' id='title' name='title' placeholder=' Ara' onChange={handleFilter} />
          <AddTalent></AddTalent>
        </div>
      </div>
      <div className='react-dataTable'>
        <DataTable
          title='ProjectCategory Listesi'
          columns={columns}
          data={searchValue.length ? filteredData : talents}
          pagination
        />
      </div>
      <div className='card-footer'>
        <div className='card-text mb-0'>
          <span className='fw-bold'>Toplam Kayıt:</span>{' '}
          <span> {talents.length}</span>
        </div>
      </div>

    </div>
  );
}
export default TalentList;
