import React from 'react';
import { useState, useEffect } from 'react';
import { addProject } from '../../../redux/slice/projectSlice';
import { fetchProjectCategories } from '../../../redux/slice/projectCategorySlice';

import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
// import { ProjectValidate } from '../../../validation/validation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function AddProject() {
  const [formModal, setFormModal] = useState(false);
  const { projectCategories } = useSelector((state) => ({
    ...state.projectCategory,
  }));
  //const handleModalShow = () => setFormModal(!formModal);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProjectCategories());
  }, []);
  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        detail: '',
        categoryId: '',
        link: '',
        sequence: '',
        image: null,
      }}
      // validationSchema={ProjectValidate}
      onSubmit={(values) => {
        dispatch(addProject(values));
      }}
    >
      {({ errors, touched, handleChange, handleSubmit, setFieldValue }) => (
        <div className='ms-auto'>
          <button
            type='button'
            className='btn btn-info'
            onClick={() => setFormModal(!formModal)}
          >
            Proje Oluştur
          </button>
          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}>Proje Ekle</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="last-name-column">Proje Adı</label>
                          <input type="text" class="form-control" placeholder="Proje Adı" id='name' onChange={handleChange} />
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" >Kategori</label>
                          <select
                            name='categoryId'
                            id='categoryId'
                            class="form-select"
                            required
                            onChange={handleChange}
                          >
                            <option value=''>Kategori Seçiniz</option>
                            {projectCategories.map((data, index) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </select>
                          {touched.categoryId && errors.categoryId && (<p>{errors.categoryId}</p>)}

                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-2">
                          <label class="form-label">Tanım</label>
                          <textarea type="text" class="form-control" placeholder="Tanım" id='description' onChange={handleChange} />
                          {touched.description && errors.description && (<p>{errors.description}</p>)}
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="mb-2">
                          <label class="form-label" for="company-column">Detay</label>
                          <ReactQuill
          id="detail"
          onChange={(value) => setFieldValue('detail', value)}
        />  {touched.detail && errors.detail && (<p>{errors.detail}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="email-id-column">Link</label>
                          <input type="text" class="form-control" placeholder="Link" id='link' onChange={handleChange} />
                          {touched.link && errors.link && (<p>{errors.link}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-2">
                          <label class="form-label" for="email-id-column">Sıra</label>
                          <input type="text" class="form-control" placeholder="Sıra" id='sequence' onChange={handleChange} />
                          {touched.sequence && errors.sequence && (<p>{errors.sequence}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div className="mb-2">
                          <label className="form-label" htmlFor="image">
                            Resim Seçin
                          </label>
                          <input
                            type="file"
                            accept="image/*"
                            id="image"
                            onChange={(event) => {
                              const selectedImage = event.target.files[0];
                              handleChange(event);
                              setFieldValue("image", selectedImage);
                            }}
                          />
                          {touched.image && errors.image && <p>{errors.image}</p>}
                        </div>
                      </div>
                     
                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Ekle</button>
                      </div>

                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default AddProject;


