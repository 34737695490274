import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import baseApi from "../../baseApi/baseApi"
const initialState = {
  admins: [],
  admin:null,
  adminLoading: true,
  refreshData:false,
  updateLoading:true,
  errorMessage: "",
}

export const fetchAdmins = createAsyncThunk(
  'admins/fetchAdmins',
  async () => {
    const response = await baseApi.get(`/admin`)
    return response.data.result
  }
)
export const fetchAdminById = createAsyncThunk(
  'admins/fetchAdminById',
  async (id) => {
    const response = await baseApi.get(`/admin/${id}`)
    return response.data.result
  }
)
export const deleteAdmin = createAsyncThunk(
  'admins/deleteAdmin',
  async (id) => {
    const response = await baseApi.delete(`/admin/${id}`)
    return response.data.result
  }
)
export const addAdmin = createAsyncThunk(
  'admins/addAdmin',
  async (values) => {
    const response = await baseApi.post(`/admin`, 
    {
      name: values.name,
      surname: values.surname,
      username: values.username,
      password: values.password,      
    })
    return response.data.result
  }
)
export const updateAdmin = createAsyncThunk(
  'admins/updateAdmin',
  async (values) => {
    const response = await baseApi.put(`/admin`, 
    {
      id: values.id,
      name: values.name,
      surname: values.surname,
      username: values.username,
      password: values.password
    })
    return response.data.result
  }
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdmins.pending, (state, action) => {
      state.adminLoading = false
    });
    builder.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.admins = action.payload
      state.adminLoading = true
      state.refreshData=false
    });
    builder.addCase(deleteAdmin.pending, (state, action) => {
      state.adminLoading = false
    });
    builder.addCase(deleteAdmin.fulfilled, (state, action) => {
      state.adminLoading = true
      state.refreshData=true
    });
    builder.addCase(addAdmin.pending, (state, action) => {
      state.adminLoading = false
    });
    builder.addCase(addAdmin.fulfilled, (state, action) => {
      state.adminLoading = true
      state.refreshData=true
    });
    builder.addCase(fetchAdminById.pending, (state, action) => {
      state.updateLoading = false
    });
    builder.addCase(fetchAdminById.fulfilled, (state, action) => {
      state.admin=action.payload
      state.updateLoading = true
    });
    builder.addCase(updateAdmin.pending, (state, action) => {
      state.adminLoading = false
    });
    builder.addCase(updateAdmin.fulfilled, (state, action) => {
      state.adminLoading = true
      state.refreshData=true
    });

  },
})

export default adminSlice.reducer