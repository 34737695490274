import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import baseApi from "../../baseApi/baseApi"
const initialState = {
  projectCategories: [],
  projectCategory:null,
  projectCategoryLoading: true,
  refreshData:false,
  updateLoading:true,
  errorMessage: "",
}

export const fetchProjectCategories = createAsyncThunk(
  'projectCategorys/fetchProjectCategories',
  async () => {
    const response = await baseApi.get(`/projectCategory`)
    return response.data.result
  }
)
export const fetchProjectCategoryById = createAsyncThunk(
  'projectCategorys/fetchProjectCategoryById',
  async (id) => {
    const response = await baseApi.get(`/projectCategory/${id}`)
    return response.data.result
  }
)
export const deleteProjectCategory = createAsyncThunk(
  'projectCategorys/deleteProjectCategory',
  async (id) => {
    const response = await baseApi.delete(`/projectCategory/${id}`)
    return response.data.result
  }
)
export const addProjectCategory = createAsyncThunk(
  'projectCategorys/addProjectCategory',
  async (values) => {
    const response = await baseApi.post(`/projectCategory`, 
    {
      name: values.name    
    })
    return response.data.result
  }
)
export const updateProjectCategory = createAsyncThunk(
  'projectCategorys/updateProjectCategory',
  async (values) => {
    const response = await baseApi.put(`/projectCategory`, 
    {
      id: values.id,
      name: values.name
    })
    return response.data.result
  }
)

export const projectCategorySlice = createSlice({
  name: 'projectCategory',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjectCategories.pending, (state, action) => {
      state.projectCategoryLoading = false
    });
    builder.addCase(fetchProjectCategories.fulfilled, (state, action) => {
      state.projectCategories = action.payload
      state.projectCategoryLoading = true
      state.refreshData=false
    });
    builder.addCase(deleteProjectCategory.pending, (state, action) => {
      state.projectCategoryLoading = false
    });
    builder.addCase(deleteProjectCategory.fulfilled, (state, action) => {
      state.projectCategoryLoading = true
      state.refreshData=true
    });
    builder.addCase(addProjectCategory.pending, (state, action) => {
      state.projectCategoryLoading = false
    });
    builder.addCase(addProjectCategory.fulfilled, (state, action) => {
      state.projectCategoryLoading = true
      state.refreshData=true
    });
    builder.addCase(fetchProjectCategoryById.pending, (state, action) => {
      state.updateLoading = false
    });
    builder.addCase(fetchProjectCategoryById.fulfilled, (state, action) => {
      state.projectCategory=action.payload
      state.updateLoading = true
    });
    builder.addCase(updateProjectCategory.pending, (state, action) => {
      state.projectCategoryLoading = false
    });
    builder.addCase(updateProjectCategory.fulfilled, (state, action) => {
      state.projectCategoryLoading = true
      state.refreshData=true
    });

  },
})

export default projectCategorySlice.reducer