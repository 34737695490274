import Lottie from "lottie-react";
import groovyWalkAnimation from "./contact.json";
const style = {
  height:"50vh",
};
const ContactAnimation = () => {
  
  return <Lottie animationData={groovyWalkAnimation}  style={style} />;
};

export default ContactAnimation;