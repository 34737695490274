import React from 'react';

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { fetchAdminById, updateAdmin } from '../../../redux/slice/adminSlice';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { PencilSquare } from 'react-bootstrap-icons';
import { AdminValidate } from '../../../validation/validation';
  

function UpdateAdmin({ id }) {
  const [formModal, setFormModal] = useState(false);
  const { admin, updateLoading } = useSelector((state) => ({ ...state.admin }));
  const dispatch = useDispatch();

  const handleClick = ({ id }) => {
    setFormModal(!formModal);
    dispatch(fetchAdminById(id));
  };

  return !updateLoading ? null : (
    <Formik
      initialValues={{
        id: admin && admin.id,
        name: admin && admin.name,
        surname: admin && admin.surname,
        username: admin && admin.username,
        password: admin && admin.password,
        confirmPassword: admin && admin.password,

      }}
      validationSchema={AdminValidate}
      onSubmit={(values) => {
        dispatch(updateAdmin(values));
      }}
    >
      {({ errors, touched, handleChange, handleSubmit }) => (
        <div>
          <PencilSquare style={{ verticalAlign: "baseline" }} onClick={() => handleClick({ id })} />
          <Modal isOpen={formModal} toggle={() => setFormModal(!formModal)} className='modal-lg'>
            <ModalHeader toggle={() => setFormModal(!formModal)}>Blog Ekle</ModalHeader>
            <ModalBody>
              <Form onSubmit={handleSubmit}>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6 col-12">
                        <div class="mb-3">
                          <label class="form-label" for="last-name-column">Admin Adı</label>
                          <input type="text" class="form-control" placeholder="Admin Adı" id='name' onChange={handleChange} defaultValue={admin && admin.name} />
                          {touched.name && errors.name && (<p>{errors.name}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-3">
                          <label class="form-label">Admin Soyadı</label>
                          <input type="text" class="form-control" placeholder="Admin Soyadı" id='surname'
                            onChange={handleChange} defaultValue={admin && admin.surname} />
                          {touched.surname && errors.surname && (<p>{errors.surname}</p>)}
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="mb-3">
                          <label class="form-label" >Şifre</label>
                          <input type="password" class="form-control" placeholder="Şifre" id='password'
                            onChange={handleChange} defaultValue={admin && admin.password} />
                          {touched.password && errors.password && (<p>{errors.password}</p>)}
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="mb-3">
                          <label class="form-label" >Şifreyi Yeniden Giriniz</label>
                          <input type="password" class="form-control" placeholder="Şifreyi Yeniden Giriniz" id='confirmPassword'
                            onChange={handleChange} defaultValue={admin && admin.password} />
                          {touched.confirmPassword && errors.confirmPassword && (<p>{errors.confirmPassword}</p>)}
                        </div>
                      </div>
                      <div class="col-md-6 col-12">
                        <div class="mb-3">
                          <label class="form-label">Kullanıcı Adı</label>
                          <input type="text" class="form-control" placeholder="Kullanıcı Adı" id='username'
                            onChange={handleChange} defaultValue={admin && admin.username} />
                          {touched.username && errors.username && (<p>{errors.username}</p>)}
                        </div>
                      </div>

                      <div class="col-12 mt-1">
                        <button type="submit" class="btn btn-primary me-1">Güncelle</button>
                      </div>

                    </div>
                  </div>
                </div>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      )}
    </Formik>
  );
}

export default UpdateAdmin;
